<template>
  <div class="supplies h-100">
    <div class="container h-100 py-5">
      <h3
        class="text-capitalize pt-2 pt-md-5"
        data-aos="fade-down-right"
        data-aos-easing="ease-in-out"
        data-aos-duration="400"
      >
        {{ this.supplies.Label }}
      </h3>
      <div
        class="row row-wrapper d-flex align-items-center justify-content-center"
      >
        <div class="row py-5">
          <div class="row pb-5">
            <div
              class="col-md-4 p-3"
              v-for="(item, supply) in this.supplies.body"
              :key="supply"
            >
              <div
                class="wrapper card shadow cursor-pointer"
                @click="openModal(item, supply)"
              >
                <div class="card-body p-3 p-md-5 text-center">
                  {{ supply }}
                </div>
                <div class="card-footer">
                  <ul
                    class="tools"
                    v-for="selected in this.final"
                    :key="selected.id"
                  >
                    <li v-if="selected.category == supply">
                      <small class="text-muted">{{ selected.name }}</small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel"></h5>
          </div>
          <div class="modal-body" id="modal_body">
            <div class="row">
              <div
                class="col-6 col-sm-4 col-md-3 col-lg-2 p-3"
                v-for="tool in this.tools"
                :key="tool.id"
              >
                <div
                  :id="tool.id"
                  class="card supply-card overflow-hidden"
                  :class="{shadowSelect: selectedTools.includes(tool)}"
                  @click="highlightTool(tool)"
                >
                  <div
                    class="card-body d-flex align-items-center cursor-pointer"
                    style="
                      height: 215px;
                      width: auto;
                      @media (max-width: 768px) {
                        height: 115px;
                      }
                    "
                  >
                    <img
                      class="d-block m-auto w-100"
                      :src=" this.baseURL + '/' + tool.img_url"
                    />
                    
                  </div>
                  <div class="card-footer supply-footer">
                    {{tool.name}}
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="apply()">
              Apply
            </button>
            <button type="button" class="btn btn-secondary" @click="close()">
              cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import store from "../store/store";
export default {
  name: "Supplies",
  data() {
    return {
      supplies: [],
      tools: [],
      selectedTools: [],
      final:[],
      currentOpenedSupply: null,
      baseURL: null,
      check: false,
    };
  },
  computed:{
    suppliesLength() {
      return this.final.length
    }
  },
  watch:{
    suppliesLength(val , oldVal){
      if(val > 0){
        this.$store.dispatch('nextNavigationStatus', true)
      }
    }
  },
  methods: {
   
    openModal(item, supply) {
      this.currentOpenedSupply = supply
      this.tools = [];
      item.forEach((value, index) => {
        this.tools.push(value);
      })

    
      $("#staticBackdrop").modal("show");

        this.final.forEach((value, index) => {
          if(this.currentOpenedSupply == value.category){
                this.selectedTools.push(value)
          }
         });
     
    },
    apply() {
      this.final = this.final.filter(el => {
        return el.category != this.currentOpenedSupply
      });
      this.selectedTools.forEach((value) => {
        this.final.push(value)
      }) 
      $("#staticBackdrop").modal("hide");

      this.selectedTools = []
    },
    
    close(){
    
      this.selectedTools = []
      this.tools.forEach((value, index) => {
        $(`#${value.id}`).removeClass('shadowSelect');
      });
      $("#staticBackdrop").modal("hide");
    

    },
    highlightTool(selectedTool) {
      $(`#${selectedTool.id}`).toggleClass('shadowSelect')
      if (this.selectedTools.includes(selectedTool)) {
        this.selectedTools.splice(this.selectedTools.indexOf(selectedTool), 1);
      } else {
        this.selectedTools.push(selectedTool);
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    if(store.getters["supplies"].state_supplies.length <= 0){
      store.state.step = store.state.step + 1
      next({ name: `step${store.state.step}`, query: {'exp': store.state.case_key} });
    }
    else{
      next()
    }
  },
  mounted() {
    this.supplies = this.$store.getters["supplies"];
    this.baseURL = axios.defaults.baseURL;
    this.$store.dispatch('prevNavigationStatus', false)
    this.$store.dispatch('nextNavigationStatus', false)
    this.$store.dispatch( 'setInstructions', this.$store.getters['supplies'].instructions )
    this.$store.dispatch( 'setStartTime')
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('supplies', this.final).then(_=>{
      this.$store.dispatch( 'setEndTime' , this.$options.name)
      next()
    })
  }
};
</script>

<style scoped>
span >>> .table {
  width: 100% !important;
}
span >>> .table >>> table {
  width: 100% !important;
}
.modal-lg {
  max-width: 1500px;
}
.tools {
  margin-bottom: unset !important;
}
.supply-footer{
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>